import React from 'react';
import './App.css';
import CustomerForm from './components/CustomerForm.js';

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <h1>Welcome to Check-In App</h1>
      </header> */}
      <main>
        <CustomerForm />
      </main>
    </div>
  );
}

export default App;
