import React, { useState } from 'react';
import axios from 'axios';
import '../css/Form.css'; // Import CSS file for styling

function CustomerForm() {
  const [formData, setFormData] = useState({
    firstname: '',
    surname: '',
    phone_number: '',
    email: '',
    check_in_date: '',
    check_out_date: '',
    check_in_time: '',
    check_out_time: '',
    frontId: null,
    backId: null
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFrontIdChange = (e) => {
    setFormData({ ...formData, frontId: e.target.files[0] });
  };

  const handleBackIdChange = (e) => {
    setFormData({ ...formData, backId: e.target.files[0] });
  };

const handleSubmit = async (e) => {
  e.preventDefault();
  
  try {
    const formDataToSend = new FormData();
    formDataToSend.append('firstname', formData.firstname);
    formDataToSend.append('surname', formData.surname);
    formDataToSend.append('phone_number', formData.phone_number);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('check_in_time', formData.check_in_time);
    formDataToSend.append('check_out_time', formData.check_out_time);
    formDataToSend.append('frontId', formData.frontId);
    formDataToSend.append('backId', formData.backId);
    formDataToSend.append('check_in_date', formData.check_in_date);
    formDataToSend.append('check_out_date', formData.check_out_date);

    const controller = new AbortController();
    const signal = controller.signal;

    const fetchPromise = fetch('https://support-hosting.gr/customers', {
      method: 'POST',
      body: formDataToSend,
      signal: signal // Pass the signal to the fetch request
    });

    // Set timeout duration in milliseconds (e.g., 5 seconds)
    const timeoutDuration = 5000;
    
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        controller.abort(); // Abort the fetch request on timeout
        reject(new Error('Request timed out')); // Reject the promise with a timeout error
      }, timeoutDuration);
    });

    // Use Promise.race to race between fetch and timeout promises
    const response = await Promise.race([fetchPromise, timeoutPromise]);

    if (!response.ok) {
      throw new Error('Error submitting customer information');
    }

    const customerData = await response.json();
    console.log(customerData.customer.email);
    // Assuming the backend returns customer data including email
    // await fetch('http://localhost:7777/send-email', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     to: customerData.customer.email,
    //     subject: 'Thank you for your check-in!',
    //     text: 'We have received your check-in information. Thank you!'
    //   })
    // });

    // Reset form after successful submission
    setFormData({
      firstname: '',
      surname: '',
      phone_number: '',
      email: '',
      check_in_time: '',
      check_out_time: '',
      check_in_date:'',
      check_out_date:'',
      frontId: null,
      backId: null
    });

    alert('Customer information submitted successfully!');
  } catch (error) {
    if (error.name === 'AbortError') {
      console.error('Request aborted due to timeout:', error);
      alert('Request aborted due to timeout. Please try again later.');
    } else {
      console.error('Error submitting customer information:', error);
      alert('Error submitting customer information. Please try again later.');
    }
  }
};


  
  return (
    <div className="customer-form-container">
      <h2>Customer Check-In Form</h2>
      <form onSubmit={handleSubmit} className="customer-form">
        <div className="form-group">
          <label>First Name:</label>
          <input
            type="text"
            name="firstname"
            value={formData.firstname}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Surname:</label>
          <input
            type="text"
            name="surname"
            value={formData.surname}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Phone Number:</label>
          <input
            type="text"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Check-In Date:</label>
          <input
            type="date"
            name="check_in_date"
            value={formData.check_in_date}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Check-Out Date:</label>
          <input
            type="date"
            name="check_out_date"
            value={formData.check_out_date}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Check-In Time:</label>
          <input
            type="time"
            name="check_in_time"
            value={formData.check_in_time}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Check-Out Time:</label>
          <input
            type="time"
            name="check_out_time"
            value={formData.check_out_time}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Front of ID:</label>
          <input type="file" onChange={handleFrontIdChange} required className="form-control-file" />
        </div>
        <div className="form-group">
          <label>Back of ID:</label>
          <input type="file" onChange={handleBackIdChange} required className="form-control-file" />
        </div>
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
}

export default CustomerForm;
